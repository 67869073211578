import { useState, useEffect, useLayoutEffect, useContext } from "react";
import { AppContext } from "../App";

export default function useDOP() {
  const { location } = useContext(AppContext);
  const [popupVisible, setPopupVisible] = useState(false);
  const [dop, setDop] = useState("September 2023");

  useLayoutEffect(() => {
    const hcpPopup = document.querySelector(".hcp-popup-header");

    if (hcpPopup) {
      setPopupVisible(true);
    } else {
      setPopupVisible(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (popupVisible) {
      setDop("September 2023");
    } else {
      switch (location.pathname) {
        case "/hcp/":
        case "/hcp":
        case "/hcp/initiating-fenhuma/":
        case "/hcp/initiating-fenhuma":
        case "/hcp/pi":
          setDop("June 2024");
          break;
        case "/patient":
        case "/patient/":
        case "/patient/side-effects":
          setDop("June 2024")
          break;
        case "/patient/useful-resources":
        case "/patient/taking-fenhuma":
          setDop("October 2023");
          break;

        default:
          setDop("September 2023");
          break;
      }
    }
  }, [popupVisible, location.pathname]);

  return dop;
}
